import React from 'react';

import './MenuBar.css'

export default function MenuItem(props) {


  const styling = `
    .cls-1{
      fill:#ed6b4d;
      stroke:#e2f3f7;
      stroke-miterlimit:10;
      stroke-width:12px;
    }
    .cls-2 {
      fill:#e2f3f7;
    }
  `;

  //get SVG path of icon to include instead of PNG because unless we make the PNGs the correct size to begin with,
  //it will cause weird scaling issues when it tries to fix the aspect ratio
  const SVGS = {
    './MenuIcons/logo.png': <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.23 762.74" width="8vh" height="8vh"><defs><style>{styling}</style></defs><path className="cls-1" d="M1022,769v96.74q0,19.28-3.79,30.81a49,49,0,0,1-13.94,20.83q-10.17,9.28-26,17.9Q932.46,960,890.12,971.41t-92.27,11.36q-58.17,0-106-17.91t-81.58-52q-33.75-34.08-51.81-82.62Q543.75,790.83,541,743.9c-1,0-1.92,0-2.9,0H256.29q-33.87,0-48.71-15t-14.84-48.54V295.58q0-22.33,6.63-36.49a41.18,41.18,0,0,1,20.78-20.6Q234.3,232,256.29,232H530.06q24.78,0,36.84,11t12,28.81q0,18.15-12,29.15t-36.84,11H296.45V437.7h215.1q23.74,0,35.44,10.65t11.7,28.11q0,17.48-11.52,28.46t-35.62,11H296.45V661.53H538.09q3.76,0,7.26.27a287.88,287.88,0,0,1,12.56-47.73q17.55-48.89,51.64-83t83-52.15q48.88-18.07,110.85-18.08,51,0,90.2,13.6t63.69,34.25q24.43,20.67,36.83,43.73t12.39,41q0,19.29-14.28,32.88a47.94,47.94,0,0,1-34.26,13.6,46.05,46.05,0,0,1-21.17-5.17,48,48,0,0,1-17-14.46q-18.93-29.6-32-44.75T852.42,550q-22.2-10.34-56.63-10.33-35.46,0-63.34,12.22a130.87,130.87,0,0,0-47.68,35.46q-19.8,23.24-30.3,57T644,719.07q0,88.81,40.8,136.67t113.77,47.85a231.88,231.88,0,0,0,66.62-9.3,337.73,337.73,0,0,0,63.17-26.51V785.85H849.15q-28.57,0-43.21-8.61T791.31,748q0-16.86,12.22-27.88t33.22-11h116q21.35,0,36.15,3.79a40.81,40.81,0,0,1,23.92,16.87Q1022,742.82,1022,769Z" transform="translate(-186.74 -226.03)"/></svg>
    ,'./MenuIcons/export.png': <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.24 898.25" width="4vh" height="4vh"><defs><style>{styling}</style></defs><path className="cls-2" d="M1020.62,789.78v249a40.18,40.18,0,0,1-40.18,40.17H219.56a40.18,40.18,0,0,1-40.18-40.17v-249a40.18,40.18,0,0,1,40.18-40.18h58.25A40.18,40.18,0,0,1,318,789.78V940.34H882V789.78a40.18,40.18,0,0,1,40.17-40.18h58.25A40.18,40.18,0,0,1,1020.62,789.78Z" transform="translate(-179.38 -180.69)"/><path className="cls-2" d="M384.19,390.1,533.64,240.66l48.21-48.21a38.5,38.5,0,0,1,55.4,0l48.22,48.22L834.9,390.1a40.17,40.17,0,0,1,0,56.81L793.71,488.1a40.17,40.17,0,0,1-56.81,0L679,430.18V751.79A40.18,40.18,0,0,1,638.81,792H580.55a40.18,40.18,0,0,1-40.17-40.17V429.91L482.19,488.1a40.17,40.17,0,0,1-56.81,0l-41.19-41.19A40.17,40.17,0,0,1,384.19,390.1Z" transform="translate(-179.38 -180.69)"/><path className="cls-2" d="M665.69,620.82a40.39,40.39,0,0,1-5.61,7L618.9,669a40.08,40.08,0,0,1-29.11,11.76A40,40,0,0,0,617.48,669Z" transform="translate(-179.38 -180.69)"/><path className="cls-2" d="M589.79,680.79A40,40,0,0,1,560.67,669l-41.19-41.19a40.14,40.14,0,0,1-5.62-7L562.08,669A40,40,0,0,0,589.79,680.79Z" transform="translate(-179.38 -180.69)"/></svg>
    ,'./MenuIcons/import.png': <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.24 898.26" width="4vh" height="4vh"><defs><style>{styling}</style></defs><path className="cls-2" d="M1020.62,789.78v249a40.18,40.18,0,0,1-40.18,40.17H219.56a40.18,40.18,0,0,1-40.18-40.17v-249a40.18,40.18,0,0,1,40.18-40.18h58.25A40.18,40.18,0,0,1,318,789.78V940.34H882V789.78a40.18,40.18,0,0,1,40.17-40.18h58.25A40.18,40.18,0,0,1,1020.62,789.78Z" transform="translate(-179.38 -180.68)"/><path className="cls-2" d="M834.91,582.54,685.46,732l-48.21,48.21a38.5,38.5,0,0,1-55.4,0L533.63,732,384.2,582.54a40.17,40.17,0,0,1,0-56.81l41.19-41.19a40.17,40.17,0,0,1,56.81,0l57.92,57.92V220.85a40.18,40.18,0,0,1,40.17-40.17h58.26a40.18,40.18,0,0,1,40.17,40.17V542.73l58.19-58.19a40.17,40.17,0,0,1,56.81,0l41.19,41.19A40.17,40.17,0,0,1,834.91,582.54Z" transform="translate(-179.38 -180.68)"/><path className="cls-2" d="M665.69,620.82a40.39,40.39,0,0,1-5.61,7L618.9,669a40.08,40.08,0,0,1-29.11,11.76A40,40,0,0,0,617.48,669Z" transform="translate(-179.38 -180.68)"/><path className="cls-2" d="M589.79,680.79A40,40,0,0,1,560.67,669l-41.19-41.19a40.14,40.14,0,0,1-5.62-7L562.08,669A40,40,0,0,0,589.79,680.79Z" transform="translate(-179.38 -180.68)"/></svg>
    ,'muted': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M301.2 34.85c-11.5-5.188-25.02-3.122-34.44 5.253L131.8 160H48c-26.51 0-48 21.49-48 47.1v95.1c0 26.51 21.49 47.1 48 47.1h83.84l134.9 119.9c5.984 5.312 13.58 8.094 21.26 8.094c4.438 0 8.972-.9375 13.17-2.844c11.5-5.156 18.82-16.56 18.82-29.16V64C319.1 51.41 312.7 40 301.2 34.85zM513.9 255.1l47.03-47.03c9.375-9.375 9.375-24.56 0-33.94s-24.56-9.375-33.94 0L480 222.1L432.1 175c-9.375-9.375-24.56-9.375-33.94 0s-9.375 24.56 0 33.94l47.03 47.03l-47.03 47.03c-9.375 9.375-9.375 24.56 0 33.94c9.373 9.373 24.56 9.381 33.94 0L480 289.9l47.03 47.03c9.373 9.373 24.56 9.381 33.94 0c9.375-9.375 9.375-24.56 0-33.94L513.9 255.1z" style={{fill: 'rgb(255, 255, 255)'}}/></svg>
    , 'unmuted' : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M412.6 182c-10.28-8.334-25.41-6.867-33.75 3.402c-8.406 10.24-6.906 25.35 3.375 33.74C393.5 228.4 400 241.8 400 255.1c0 14.17-6.5 27.59-17.81 36.83c-10.28 8.396-11.78 23.5-3.375 33.74c4.719 5.806 11.62 8.802 18.56 8.802c5.344 0 10.75-1.779 15.19-5.399C435.1 311.5 448 284.6 448 255.1S435.1 200.4 412.6 182zM473.1 108.2c-10.22-8.334-25.34-6.898-33.78 3.34c-8.406 10.24-6.906 25.35 3.344 33.74C476.6 172.1 496 213.3 496 255.1s-19.44 82.1-53.31 110.7c-10.25 8.396-11.75 23.5-3.344 33.74c4.75 5.775 11.62 8.771 18.56 8.771c5.375 0 10.75-1.779 15.22-5.431C518.2 366.9 544 313 544 255.1S518.2 145 473.1 108.2zM534.4 33.4c-10.22-8.334-25.34-6.867-33.78 3.34c-8.406 10.24-6.906 25.35 3.344 33.74C559.9 116.3 592 183.9 592 255.1s-32.09 139.7-88.06 185.5c-10.25 8.396-11.75 23.5-3.344 33.74C505.3 481 512.2 484 519.2 484c5.375 0 10.75-1.779 15.22-5.431C601.5 423.6 640 342.5 640 255.1S601.5 88.34 534.4 33.4zM301.2 34.98c-11.5-5.181-25.01-3.076-34.43 5.29L131.8 160.1H48c-26.51 0-48 21.48-48 47.96v95.92c0 26.48 21.49 47.96 48 47.96h83.84l134.9 119.8C272.7 477 280.3 479.8 288 479.8c4.438 0 8.959-.9314 13.16-2.835C312.7 471.8 320 460.4 320 447.9V64.12C320 51.55 312.7 40.13 301.2 34.98z" style={{fill: 'rgb(255, 255, 255)'}}/></svg>
    , 'settings' : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M495.9 166.6C499.2 175.2 496.4 184.9 489.6 191.2L446.3 230.6C447.4 238.9 448 247.4 448 256C448 264.6 447.4 273.1 446.3 281.4L489.6 320.8C496.4 327.1 499.2 336.8 495.9 345.4C491.5 357.3 486.2 368.8 480.2 379.7L475.5 387.8C468.9 398.8 461.5 409.2 453.4 419.1C447.4 426.2 437.7 428.7 428.9 425.9L373.2 408.1C359.8 418.4 344.1 427 329.2 433.6L316.7 490.7C314.7 499.7 307.7 506.1 298.5 508.5C284.7 510.8 270.5 512 255.1 512C241.5 512 227.3 510.8 213.5 508.5C204.3 506.1 197.3 499.7 195.3 490.7L182.8 433.6C167 427 152.2 418.4 138.8 408.1L83.14 425.9C74.3 428.7 64.55 426.2 58.63 419.1C50.52 409.2 43.12 398.8 36.52 387.8L31.84 379.7C25.77 368.8 20.49 357.3 16.06 345.4C12.82 336.8 15.55 327.1 22.41 320.8L65.67 281.4C64.57 273.1 64 264.6 64 256C64 247.4 64.57 238.9 65.67 230.6L22.41 191.2C15.55 184.9 12.82 175.3 16.06 166.6C20.49 154.7 25.78 143.2 31.84 132.3L36.51 124.2C43.12 113.2 50.52 102.8 58.63 92.95C64.55 85.8 74.3 83.32 83.14 86.14L138.8 103.9C152.2 93.56 167 84.96 182.8 78.43L195.3 21.33C197.3 12.25 204.3 5.04 213.5 3.51C227.3 1.201 241.5 0 256 0C270.5 0 284.7 1.201 298.5 3.51C307.7 5.04 314.7 12.25 316.7 21.33L329.2 78.43C344.1 84.96 359.8 93.56 373.2 103.9L428.9 86.14C437.7 83.32 447.4 85.8 453.4 92.95C461.5 102.8 468.9 113.2 475.5 124.2L480.2 132.3C486.2 143.2 491.5 154.7 495.9 166.6V166.6zM256 336C300.2 336 336 300.2 336 255.1C336 211.8 300.2 175.1 256 175.1C211.8 175.1 176 211.8 176 255.1C176 300.2 211.8 336 256 336z" style={{fill: 'rgb(255, 255, 255)'}}/></svg>
  }
  // let size = props.text==='' ? '100%' : '70%';
  let text = props.img==='' ? "100%" : "70%";
  // let img_vis = props.img==='' ? "hidden" : "visable";

  const style = props.custom_style;

  return (
    <div style={style} className={`menu-item ${props.classString || ''}`} onClick={props.onClick} aria-label={props.label}>
      {SVGS[props.img]}
      {/* <img style={{visibility:img_vis}} src={props.img} alt={props.text} width={size+""} height={size+""}/> */}
      <h4 className="menu-item-label" style={{fontSize: text}}>{props.text}</h4>
    </div>
  )
}

